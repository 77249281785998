<template>
  <div class="p-3">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group label-cols="5" label="Kapıda Ödeme">
              <b-form-checkbox class="float-right" :checked="form.kapida_odeme" v-model="form.kapida_odeme" switch size="lg" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label-cols="5" label="K.K Ödeme">
              <b-form-checkbox class="float-right" :checked="form.k_k_odeme" v-model="form.k_k_odeme" switch size="lg" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label-cols="5" label="Havale | EFT">
              <b-form-checkbox class="float-right" :checked="form.havale_eft" v-model="form.havale_eft" switch size="lg" />
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="form.k_k_odeme">
            <b-form-group label="Ödeme Türü" description="Kredi Kartı Ödeme Sistemi">
              <v-select
                v-model="form.k_k_odeme_turu"
                :options="odemeTuru"
                :reduce="(odeme) => odeme.id"
                label="title"
                :clearable="true"
                placeholder="Ödeme Türü"
                class="invoice-filter-select d-block select-size-lg"
                @input="handlerChangeOdemeTuru"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-card class="m-2 rounded-0 border-info" v-if="form.k_k_odeme && form.k_k_odeme_turu == 'iyzico'">
          <b-row>
            <b-col cols="12" class="border-bottom">
              <h5>İyzico Bilgileri</h5>
            </b-col>
            <b-col cols="12" class="pt-3">
              <validation-provider name="Api Anahtarı" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Api Anahtarı">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    ref="iyzico_api"
                    v-model="form.odeme_turu_bilgi['iyzico_api']"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider name="Güvenlik Anahtarı" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Güvenlik Anahtarı">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    ref="iyzico_guvenlik"
                    v-model="form.odeme_turu_bilgi['iyzico_guvenlik']"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider name="Uri" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Uri">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    ref="iyzico_uri"
                    v-model="form.odeme_turu_bilgi['iyzico_uri']"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider name="Iyzicoya Bağlı Banka" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Iyzicoya Bağlı Banka" :class="{ 'is-invalid': !!validationContext.errors[0] }">
                  <v-select
                    v-model="form.odeme_turu_bilgi['iyzico_banka']"
                    :options="bankalar"
                    :reduce="(banka) => banka.banka_iban"
                    :clearable="true"
                    class="invoice-filter-select d-block select-size-lg"
                    :class="{ 'is-invalid': !!validationContext.errors[0] }"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback "
                  >
                    <template slot="selected-option" slot-scope="option">
                      <span class="font-weight-bold pr-3"> {{ option.banka_hesap_turu }}</span>
                      {{ option.banka_adi }}
                    </template>
                    <template slot="option" slot-scope="option">
                      <span class="font-weight-bold pr-3"> {{ option.banka_hesap_turu }}</span>
                      {{ option.banka_adi }}
                    </template>
                  </v-select>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label-cols="5" label="Taksitli Ödeme">
                <b-form-checkbox
                  class="float-right"
                  :checked="form.odeme_turu_bilgi['taksit']"
                  v-model="form.odeme_turu_bilgi['taksit']"
                  switch
                  size="lg"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="m-2 rounded-0 border-info" v-if="form.k_k_odeme && form.k_k_odeme_turu == 'paymax'">
          <b-row>
            <b-col cols="12" class="border-bottom">
              <h5>Paymax Bilgileri</h5>
              <small>
                <a href="https://merchant.paymax.com.tr/api-manager/api-information.html" target="_blank"
                  >https://merchant.paymax.com.tr/api-manager/api-information.html
                </a>
              </small>
            </b-col>
            <b-col cols="12" class="pt-3">
              <validation-provider name="Kullanıcı Adı" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Kullanıcı Adı">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    ref="iyzico_api"
                    v-model="form.odeme_turu_bilgi['userName']"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider name="Güvenlik Anahtarı" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Güvenlik Anahtarı">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    ref="iyzico_guvenlik"
                    v-model="form.odeme_turu_bilgi['password']"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider name="Mağaza Kodu" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Mağaza Kodu">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    ref="iyzico_uri"
                    v-model="form.odeme_turu_bilgi['shopCode']"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <validation-provider name="Hash Anahtar" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Hash Anahtar">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    ref="iyzico_uri"
                    v-model="form.odeme_turu_bilgi['hash']"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider name="Callback Url" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group label="Callback Url">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    ref="iyzico_uri"
                    v-model="form.odeme_turu_bilgi['callback_url']"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>
        <b-row>
          <b-col cols="12" md="6">
            <div class="d-flex">
              <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
                <i class="fad fa-save pr-2"></i>
                {{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}
              </b-button>
              <b-button squared size="lg" variant="danger">
                <i class="fad fa-trash"></i>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
export default defineComponent({
  components: { vSelect },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.bankalar = computed(() => store.getters.getBankalar);
    store.dispatch('bankaListele', { statu: true });
    expo.form = ref({
      k_no: null,
      kapida_odeme: false,
      k_k_odeme: false,
      havale_eft: false,
      k_k_odeme_turu: null,
      odeme_turu_bilgi: {},
    });

    expo.odemeTuru = ref([
      {
        id: 'iyzico',
        title: 'İyzico',
      },
      {
        id: 'paymax',
        title: 'Paymax',
      },
      {
        id: 'paytr',
        title: 'Paytr',
      },
    ]);

    expo.handlerChangeOdemeTuru = (event) => {
      if (event == 'iyzico') {
        expo.form.value.odeme_turu_bilgi = {
          iyzico_api: null,
          iyzico_guvenlik: null,
          iyzico_uri: null,
          iyzico_banka: null,
        };
      } else if (event == 'paymax') {
        expo.form.value.odeme_turu_bilgi = {
          userName: null,
          password: null,
          shopCode: null,
          hash: null,
          callback_url: 'https://app.interyum.com/api/e-ticaret/siparis-odeme-paymax-callback-post',
        };
      }
    };

    const handlerConfig = async () => {
      context.emit('show', true);
      await store.dispatch('odemeSistemiGetir').then((res) => {
        if (res.data.data !== null) {
          expo.form.value = res.data.data;
        }
      });
      context.emit('show', false);
    };

    handlerConfig();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('odemeSistemiEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(expo.form.value.k_no == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
          }
        })
        .catch((err) => {
          context.emit('show', false);
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
